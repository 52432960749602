import { Card, CardBody } from '@paljs/ui/Card';
import { InputGroup } from '@paljs/ui/Input';
import { Button } from '@paljs/ui/Button';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import SEO from '../../components/SEO';
import axios from 'axios';
import { EvaIcon } from '@paljs/ui/Icon';
import { GlobalStateData } from '../../storage/GlobalDataProvider';
import '../../styles/common.css';
import { CSVLink, CSVDownload } from 'react-csv';
import Select from '@paljs/ui/Select';
import {
  requireCheck,
  resetRequired,
  pullDownReset,
  baseUri,
  showLoader,
  hideLoader,
  numFormatter,
  authCheck,
  dateFormat,
  dateGreaterOrEqual,
  measures,
  refreshPage,
} from '../../utils/utils';
import { getFiltered } from '../../utils/table';
import DataTable from 'react-data-table-component';
import { isBrowser } from '..';

const Input = styled(InputGroup)`
  margin-bottom: 10px;
`;

const ActionBtn = styled(Button)`
  padding: 4px;
  margin-bottom: 10px;
`;

const SelectField = styled(Select)`
  margin-bottom: 10px;
`;

const ZeroLotNumberQueryReport = ({ serverData }: any) => {
  if (serverData === undefined && isBrowser()) {
    refreshPage();
  }
  const itemData = serverData?.reportData;
  const [tableFilter, setTableFilter] = useState('');
  const [tableData, setTableData] = useState(itemData);
  const state = useContext(GlobalStateData);

  useEffect(() => {
    authCheck(state.authData);
    hideLoader();
  }, []);

  const columns = [
    {
      name: 'ID',
      selector: (row) => `${row.id}`,
      sortable: true,
      maxWidth: '25%',
    },
    {
      name: 'Meters Processed',
      selector: (row) => `${row.meters_processed}`,
      sortable: true,
      maxWidth: '25%',
    },
    {
      name: 'GRA Id',
      selector: (row) => `${row.grey_receipt_advice_id}`,
      sortable: true,
      maxWidth: '25%',
    },
    {
      name: 'Receipt No',
      selector: (row) => `${row.receipt_number}`,
      sortable: true,
      maxWidth: '25%',
    },
  ];
  const filterColumns = ['id', 'meters_processed', 'grey_receipt_advice_id', 'receipt_number'];

  const filterChange = (searchInput, newData = false) => {
    setTableFilter(searchInput);
    let available = [];
    if (newData) {
      available = newData;
    } else {
      available = itemData;
    }
    if (searchInput) {
      let filteredData = getFiltered(searchInput, available, filterColumns, true);
      setTableData(filteredData);
    } else {
      setTableData(available);
    }
  };

  return (
    <>
      <SEO title="Zero Lot Number Query" />
      <Row>
        <Col breakPoint={{ xs: 12, md: 12 }}>
          <Card>
            <header>ZERO Lot Number Query</header>
            <CardBody className="cardbody">
              <Input fullWidth size="Small">
                <input
                  type="text"
                  onChange={(e) => filterChange(e.target.value)}
                  placeholder="Filter"
                  name="tableFilter"
                  value={tableFilter}
                />
              </Input>
              <DataTable
                columns={columns}
                data={tableData}
                fixedHeader
                allowOverflow={false}
                overflowY={false}
                overflowX={false}
                wrap={false}
                highlightOnHover={true}
                pagination={true}
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export async function getServerData() {
  let config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const requests = await axios.get(baseUri() + 'reports/zero-lot-number', config);
  const reportData = await requests.data.data;

  return {
    props: {
      reportData,
    },
  };
}

export default ZeroLotNumberQueryReport;
